@charset "UTF-8";
a.itext {
  width: auto !important;
  height: auto !important;
}

.annot {
  display: none !important;
}

::selection {
  background-color: #d0d0ff;
}

.pcnone {
  display: none;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .pcnone {
    display: block;
  }
}

@media screen and (max-width: 640px) {
  .spnone {
    display: none;
  }
}

@media only screen and (min-width: 835px) and (max-width: 1200px) {
  .bp1200none {
    display: none;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .bp1200none {
    display: block;
  }
}

@media only screen and (min-width: 835px) and (max-width: 1000px) {
  .bp1000none {
    display: none;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .bp1000none {
    display: block;
  }
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

li {
  list-style: none;
}

html {
  font-size: 62.5% !important;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 400;
}

body {
  counter-reset: number 0;
  line-height: 1.7em;
  letter-spacing: 0.27em;
  font-weight: 400;
  color: #222222;
  word-wrap: break-word;
  overflow: hidden;
  font-size: 1.6rem;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  animation: fadeInAnime 1.5s ease 0s;
}

.inner {
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
}

@media only screen and (min-width: 835px) and (max-width: 1200px) {
  .inner {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media screen and (max-width: 834px) {
  .inner {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.inner2 {
  width: calc(100% - 12%);
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
}

#contents {
  width: 100%;
}

@media only screen and (min-width: 835px) and (max-width: 1200px) {
  #contents {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media screen and (max-width: 834px) {
  #contents {
    padding-left: 24px;
    padding-right: 24px;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.tdpd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  color: #17e012;
  transition: 1s;
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .imgR, .imgL {
    max-width: 30% !important;
  }
}

@media screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    margin: 0 auto 10px;
    max-width: 100% !important;
    text-align: center;
  }
}

.mincho {
  font-family: "Noto Serif JP", "Times New Roman", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

header#global_header {
  min-width: auto;
}

footer#global_footer {
  min-width: auto;
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
  text-align: center;
}

#g-nav.panelactive {
  background-color: #fff;
}

#g-nav.panelactive .sp_contact {
  margin-top: 30px;
}

.navR {
  display: flex;
  align-items: center;
}

#g-nav {
  position: relative;
}

@media only screen and (min-width: 835px) and (max-width: 900px) {
  #g-nav {
    display: block;
  }
}

@media screen and (max-width: 834px) {
  #g-nav {
    display: block;
  }
}

#g-nav .mainNav {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media screen and (max-width: 834px) {
  #g-nav .mainNav {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    padding: 50px;
  }
}

@media only screen and (min-width: 835px) and (max-width: 900px) {
  #g-nav .mainNav {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    padding: 50px;
  }
}

#g-nav .mainNav li {
  position: relative;
}

#g-nav .mainNav li a {
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  padding: 10px 15px;
  display: block;
  position: relative;
  color: #222222;
}

@media screen and (max-width: 834px) {
  #g-nav .mainNav li a {
    display: block;
    padding: 15px;
    font-size: 1.5em;
    border-bottom: 1px solid #f7f7f7;
    text-align: left;
    position: relative;
  }
  #g-nav .mainNav li a:before {
    font-family: "Font Awesome 5 pro";
    content: "\f054";
    position: absolute;
    font-size: 14px;
    right: 5%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

@media only screen and (min-width: 835px) and (max-width: 900px) {
  #g-nav .mainNav li a {
    display: block;
    padding: 15px;
    font-size: 1.5em;
    border-bottom: 1px solid #f7f7f7;
    text-align: left;
    position: relative;
  }
  #g-nav .mainNav li a:before {
    font-family: "Font Awesome 5 pro";
    content: "\f054";
    position: absolute;
    font-size: 14px;
    right: 5%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

@media only screen and (min-width: 835px) and (max-width: 900px) {
  #g-nav {
    /*position:fixed;にし、z-indexの数値を大きくして前面へ*/
    position: fixed;
    z-index: 9999;
    /*ナビのスタート位置と形状*/
    top: -2000%;
    left: 0;
    right: 0;
    height: 100vh;
    /*ナビの高さ*/
    /*動き*/
    transition: all 0.6s;
  }
  /*アクティブクラスがついたら位置を0に*/
  #g-nav.panelactive {
    top: 0;
    margin-top: 0;
  }
}

@media screen and (max-width: 834px) {
  #g-nav {
    /*position:fixed;にし、z-indexの数値を大きくして前面へ*/
    position: fixed;
    z-index: 9999;
    /*ナビのスタート位置と形状*/
    top: -2000%;
    left: 0;
    right: 0;
    height: 100vh;
    /*ナビの高さ*/
    /*動き*/
    transition: opacity 6s ease, visibility 6s ease;
  }
  /*アクティブクラスがついたら位置を0に*/
  #g-nav.panelactive {
    top: 0;
    margin-top: 0;
  }
}

#toggle .is-open {
  display: block;
}

.dropdown {
  width: 250px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
  display: none;
}

@media only screen and (min-width: 835px) and (max-width: 900px) {
  .dropdown {
    position: inherit;
    transform: none;
    top: 0;
    left: 0;
    margin: auto;
    width: 100%;
  }
}

@media screen and (max-width: 834px) {
  .dropdown {
    position: inherit;
    transform: none;
    top: 0;
    left: 0;
    margin: auto;
    width: 100%;
  }
}

.dropdown li:last-of-type a {
  border-bottom: none;
}

.dropdown li a {
  background-color: #e4007f;
  display: block;
  color: #fff !important;
  border-bottom: 1px solid #fff;
  padding: 15px !important;
}

@media only screen and (min-width: 835px) and (max-width: 900px) {
  .dropdown li a {
    border-bottom: 1px solid #ccc;
    background-color: #fff;
    color: #222222 !important;
  }
}

@media screen and (max-width: 834px) {
  .dropdown li a {
    border-bottom: 1px solid #ccc;
    background-color: #fff;
    color: #222222 !important;
  }
}

.toggle {
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

@media screen and (max-width: 834px) {
  .toggle {
    z-index: 100;
  }
}

/*ハンバーガーメニュー*/
.openBtn {
  display: none;
}

@media only screen and (min-width: 835px) and (max-width: 900px) {
  .openBtn {
    display: block;
  }
}

@media screen and (max-width: 834px) {
  .openBtn {
    display: block;
  }
}

/*ボタン外側※レイアウトによってpositionや形状は適宜変更してください*/
.openBtn {
  position: fixed;
  /*ボタン内側の基点となるためrelativeを指定*/
  top: 0;
  right: 0;
  cursor: pointer;
  width: 100px;
  height: 100px;
  box-shadow: -29px 28px 41px -49px #dcdada;
  z-index: 10000;
}

.btnArea {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

/*ボタン内側*/
.openBtn span {
  display: inline-block;
  transition: all .4s;
  /*アニメーションの設定*/
  height: 1px;
  border-radius: 5px;
  background: #222222;
  width: 35%;
}

.openBtn span:nth-of-type(1) {
  width: 50%;
}

.openBtn span:nth-of-type(2) {
  width: 35%;
  margin: 15px 0 0 -15px;
}

/*activeクラスが付与されると線が回転して×になり、Menu⇒Closeに変更*/
.openBtn.active span:nth-of-type(1) {
  top: 14px;
  left: 18px;
  transform: translateY(4px) rotate(-45deg);
  width: 40%;
}

.openBtn.active span:nth-of-type(2) {
  top: 26px;
  left: 18px;
  transform: translateY(-2px) rotate(45deg);
  width: 40%;
  margin: 5px 0;
}

.h_tel a,
.sp_tel a {
  display: block;
  background: #64b3f4;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #c2e59c, #64b3f4);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #c2e59c, #64b3f4);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  padding: 15px;
  color: #fff;
  padding: 30px;
  width: 200px;
  background-size: 200% 200%;
  /*サイズを大きくひきのばす*/
  animation: bggradient 10s ease infinite;
}

.h_tel a img,
.sp_tel a img {
  display: block;
  margin: 0 auto 10px;
}

@media only screen and (min-width: 835px) and (max-width: 1000px) {
  .h_tel a,
  .sp_tel a {
    width: 120px;
  }
}

.sp_tel {
  display: none;
}

.sp_tel a {
  margin: 30px auto auto;
  width: 100%;
}

@media only screen and (min-width: 835px) and (max-width: 900px) {
  .sp_tel {
    display: block;
  }
}

@media screen and (max-width: 834px) {
  .sp_tel {
    display: block;
  }
}

@media only screen and (min-width: 835px) and (max-width: 900px) {
  .h_tel {
    display: none;
  }
}

@media screen and (max-width: 834px) {
  .h_tel {
    display: none;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 50px 0;
}

@media screen and (max-width: 834px) {
  .header {
    padding: 30px 0;
  }
}

@media screen and (max-width: 834px) {
  .header .h-logo {
    text-align: center;
    width: 100%;
  }
}

@media only screen and (min-width: 835px) and (max-width: 900px) {
  .header .h-logo {
    text-align: center;
    width: 100%;
  }
}

.header {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: calc(100% - 190px);
  margin: auto;
  height: fit-content;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  will-change: transform;
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease, -webkit-transform .4s ease;
}

@media screen and (max-width: 834px) {
  .header {
    width: 100%;
  }
}

@media only screen and (min-width: 835px) and (max-width: 1200px) {
  .header {
    width: 100%;
    justify-content: center;
    padding: 50px 24px;
  }
}

@media only screen and (min-width: 835px) and (max-width: 900px) {
  .header {
    width: 100%;
    justify-content: space-between;
  }
}

.header--unpinned {
  -webkit-transform: translateY(-150%);
  transform: translateY(-150%);
}

.header--pinned {
  background-color: #fff;
}

@media only screen and (min-width: 835px) and (max-width: 900px) {
  .header--pinned {
    background-color: inherit;
  }
}

@media screen and (max-width: 834px) {
  .header--pinned {
    background-color: inherit;
  }
}

.header--pinned #g-nav {
  display: block;
}

@media screen and (max-width: 834px) {
  .h_contact {
    display: none;
  }
}

@media only screen and (min-width: 835px) and (max-width: 900px) {
  .h_contact {
    display: none;
  }
}

.h_contact .h_mail {
  display: block;
  font-size: 1.12em;
  background-color: #e4007f;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  padding: 24px;
  min-width: 210px;
}

.sp_contact {
  display: none;
}

@media screen and (max-width: 834px) {
  .sp_contact {
    display: block;
  }
}

@media only screen and (min-width: 835px) and (max-width: 900px) {
  .sp_contact {
    display: block;
  }
}

.sp_contact .h_mail {
  display: block;
  font-size: 1.12em;
  background-color: #e4007f;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  padding: 24px;
  min-width: 210px;
}

.mainWrap {
  position: relative;
}

.mainWrap .mainArea {
  width: calc(100% - 190px);
  margin: auto;
}

@media screen and (max-width: 834px) {
  .mainWrap .mainArea {
    width: calc(100% - 48px);
  }
}

.mainWrap .mainArea img {
  width: 100% !important;
}

.subArea {
  padding: 15rem 0;
  background-color: #fef7fb;
}

@media screen and (max-width: 834px) {
  .subArea {
    padding: 5rem 0;
  }
}

.subArea h1 {
  font-size: clamp(32px, 5vw, 50px);
  line-height: 1.25em;
  font-family: 'Montserrat', sans-serif;
}

.pankuzu {
  margin-top: 12px;
  margin-bottom: 4.8rem;
  font-size: 1.4rem;
  display: flex;
  justify-content: center;
  color: #222222;
}

@media screen and (max-width: 640px) {
  .pankuzu {
    margin-top: 0;
  }
}

.pankuzu ul div::before {
  content: "\f105";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  padding: 0 10px;
}

.pankuzu ul li:last-child a {
  pointer-events: none;
  color: #21120e;
  text-decoration: none;
}

footer#global_footer {
  background-color: #e4007f;
  padding: 100px 0;
  color: #fff;
  font-size: 14px;
}

footer#global_footer .footer {
  display: flex;
  justify-content: space-between;
  max-width: 1730px;
  margin: auto;
}

@media only screen and (min-width: 835px) and (max-width: 1730px) {
  footer#global_footer .footer {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media only screen and (min-width: 835px) and (max-width: 1000px) {
  footer#global_footer .footer {
    display: block;
  }
}

@media screen and (max-width: 834px) {
  footer#global_footer .footer {
    display: block;
  }
}

footer#global_footer .footer .ft_L {
  text-align: left;
}

@media only screen and (min-width: 835px) and (max-width: 1000px) {
  footer#global_footer .footer .ft_L {
    text-align: center;
  }
}

@media screen and (max-width: 834px) {
  footer#global_footer .footer .ft_L {
    text-align: center;
  }
}

footer#global_footer .footer .ft_L .ft_logo {
  margin-bottom: 40px;
}

footer#global_footer .footer .ft_L .ft_logo img {
  filter: brightness(10) invert(0);
}

footer#global_footer .footer .ft_R {
  text-align: right;
}

@media only screen and (min-width: 835px) and (max-width: 1000px) {
  footer#global_footer .footer .ft_R {
    margin-top: 60px;
    text-align: center;
  }
}

@media screen and (max-width: 834px) {
  footer#global_footer .footer .ft_R {
    margin-top: 60px;
    text-align: center;
  }
}

footer#global_footer .ftNav {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 60px;
}

@media screen and (max-width: 834px) {
  footer#global_footer .ftNav li {
    width: 100%;
  }
}

footer#global_footer .ftNav li a {
  font-family: 'Montserrat', sans-serif;
  padding: 10px;
  display: block;
  line-height: 1em;
  color: #fff;
}

@media screen and (max-width: 834px) {
  footer#global_footer .ftNav li a {
    text-align: center;
  }
}

footer#global_footer #copy {
  color: #fff !important;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
}

footer#global_footer #copy a {
  text-decoration: none;
  color: #fff !important;
}

.title_01 h2 {
  font-size: clamp(30px, 5vw, 50px);
  line-height: 1.25em;
  color: #e4007f;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
}

.title_01 h2 .ls {
  letter-spacing: 0.27em;
}

.title_01 h2 .sub {
  color: #595959;
  font-size: 20px;
  display: block;
  font-weight: 400;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media screen and (max-width: 834px) {
  .title_01 h2 .sub {
    font-size: 14px;
  }
}

.title_02 h2 {
  font-size: clamp(40px, 4vw, 50px);
  line-height: 1.25em;
  color: #e4007f;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
}

.title_02 h2 .ls {
  letter-spacing: 0.27em;
}

.title_02 h2 .ja {
  font-size: clamp(30px, 5vw, 50px);
  color: #222222;
  display: block;
  font-weight: 400;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  margin-top: 50px;
}

.sub_title_01 h3 {
  color: #e4007f;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  line-height: 1.25em;
  font-size: 1.5em;
}

@media screen and (max-width: 640px) {
  .sub_title_01 h3 {
    text-align: center !important;
  }
}

.sub_title_01 h3 .ja {
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-size: 14px;
  margin-left: 60px;
  font-weight: 400;
}

@media screen and (max-width: 640px) {
  .sub_title_01 h3 .ja {
    display: block;
    margin-left: 0;
  }
}

.sub_title_02 h3 {
  font-size: clamp(40px, 5vw, 50px);
  line-height: 1.5em;
}

.sub_title_02 h3 .job {
  font-size: 24px;
  margin-bottom: 40px;
  display: block;
}

.sub_title_02 h3 .job .en {
  font-family: 'Montserrat', sans-serif;
}

.sub_title_02 h3 .en2 {
  font-size: 20px;
  font-family: 'Montserrat', sans-serif;
  margin-left: 70px;
}

@media screen and (max-width: 640px) {
  .sub_title_02 h3 .en2 {
    display: block;
    margin-left: 0;
  }
}

.cont_01 {
  position: relative;
}

.cont_01::before {
  content: "";
  width: 100%;
  height: 305px;
  position: absolute;
  top: -80px;
  left: 0;
  right: 0;
  background-color: #fef7fb;
  z-index: 0;
}

@media screen and (max-width: 834px) {
  .cont_01::before {
    z-index: -1;
  }
}

.cont_01 .in {
  display: flex;
  justify-content: space-between;
  max-width: 1757px;
  margin-left: auto;
}

@media screen and (max-width: 834px) {
  .cont_01 .in {
    display: block;
  }
}

.cont_01 .in .flxL {
  width: 30%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .cont_01 .in .flxL .imgL {
    max-width: 100% !important;
    float: none !important;
  }
}

@media screen and (max-width: 834px) {
  .cont_01 .in .flxL {
    width: 100% !important;
    text-align: center;
  }
}

.cont_01 .in .flxR {
  width: 65%;
}

@media screen and (max-width: 834px) {
  .cont_01 .in .flxR {
    width: 100% !important;
    margin-top: 40px;
  }
}

.cont_01 .in .flxL {
  z-index: 0;
}

@media only screen and (min-width: 835px) and (max-width: 1757px) {
  .cont_01 .in {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media screen and (max-width: 834px) {
  .cont_01 .in {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.cont_01 .in .text {
  max-width: 700px;
}

.cont_01 .in .deco {
  position: absolute;
  right: 0;
  bottom: -25%;
}

@media only screen and (min-width: 835px) and (max-width: 1757px) {
  .cont_01 .in .deco {
    bottom: -45%;
  }
}

@media only screen and (min-width: 835px) and (max-width: 1420px) {
  .cont_01 .in .deco {
    bottom: -55%;
  }
}

@media only screen and (min-width: 835px) and (max-width: 1200px) {
  .cont_01 .in .deco {
    position: inherit;
  }
}

@media screen and (max-width: 834px) {
  .cont_01 .in .deco {
    position: inherit;
  }
}

.cont_02 .in {
  margin-left: auto;
  position: relative;
}

.cont_02 .in .flxL {
  max-width: 1200px;
  margin-left: auto;
}

.cont_02 .in .flxR {
  background-color: #ffffff;
  padding: 100px 80px 0;
  min-width: 1090px;
  max-width: 1090px;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media only screen and (min-width: 835px) and (max-width: 1420px) {
  .cont_02 .in .flxR {
    bottom: -300px;
  }
}

@media only screen and (min-width: 835px) and (max-width: 1200px) {
  .cont_02 .in .flxR {
    min-width: 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 834px) {
  .cont_02 .in .flxR {
    position: inherit;
    min-width: 100%;
    max-width: 100%;
    bottom: unset;
    padding: 80px 30px 0;
  }
}

@media only screen and (min-width: 835px) and (max-width: 1420px) {
  .cont_02 {
    margin: 250px 0 500px 0 !important;
  }
}

@media only screen and (min-width: 835px) and (max-width: 1200px) {
  .cont_02 {
    margin: 200px 0 500px 0 !important;
  }
}

.cont_03 {
  background-color: #fef7fb;
  padding: 120px 0;
}

.cont_03 .dlWrap {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 834px) {
  .cont_03 .dlWrap {
    display: block;
  }
}

.cont_03 .dlWrap .flxL {
  width: 45%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .cont_03 .dlWrap .flxL .imgL {
    max-width: 100% !important;
    float: none !important;
  }
}

@media screen and (max-width: 834px) {
  .cont_03 .dlWrap .flxL {
    width: 100% !important;
    text-align: center;
  }
}

.cont_03 .dlWrap .flxR {
  width: 45%;
}

@media screen and (max-width: 834px) {
  .cont_03 .dlWrap .flxR {
    width: 100% !important;
    margin-top: 40px;
  }
}

.cont_04 .in {
  margin-right: auto;
  position: relative;
}

.cont_04 .in .flxL {
  max-width: 1200px;
  margin-right: auto;
}

.cont_04 .in .flxR {
  background-color: #ffffff;
  padding: 100px 80px 0;
  min-width: 900px;
  max-width: 900px;
  position: absolute;
  bottom: 0;
  right: 0;
}

@media only screen and (min-width: 835px) and (max-width: 1420px) {
  .cont_04 .in .flxR {
    bottom: -300px;
  }
}

@media only screen and (min-width: 835px) and (max-width: 1200px) {
  .cont_04 .in .flxR {
    min-width: 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 834px) {
  .cont_04 .in .flxR {
    position: inherit;
    min-width: 100%;
    max-width: 100%;
    bottom: unset;
    padding: 80px 30px 0;
  }
}

@media only screen and (min-width: 835px) and (max-width: 1420px) {
  .cont_04 {
    margin: 250px 0 500px 0 !important;
  }
}

@media only screen and (min-width: 835px) and (max-width: 1200px) {
  .cont_04 {
    margin: 200px 0 500px 0 !important;
  }
}

.cont_05 {
  background-color: #fef7fb;
  padding: 120px 0;
}

@media only screen and (min-width: 835px) and (max-width: 1730px) {
  .cont_05 {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media screen and (max-width: 834px) {
  .cont_05 {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.cont_05 .in {
  max-width: 1730px;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 834px) {
  .cont_05 .in {
    display: block;
  }
}

.cont_05 .in .flxL {
  width: 42%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .cont_05 .in .flxL .imgL {
    max-width: 100% !important;
    float: none !important;
  }
}

@media screen and (max-width: 834px) {
  .cont_05 .in .flxL {
    width: 100% !important;
    text-align: center;
  }
}

.cont_05 .in .flxR {
  width: 52%;
}

@media screen and (max-width: 834px) {
  .cont_05 .in .flxR {
    width: 100% !important;
    margin-top: 40px;
  }
}

@media only screen and (min-width: 835px) and (max-width: 900px) {
  .cont_05 .in {
    display: block;
  }
  .cont_05 .in .flxL, .cont_05 .in .flxR {
    width: 100% !important;
  }
}

@media only screen and (min-width: 835px) and (max-width: 900px) {
  .cont_05 .in .flxR {
    margin-top: 40px;
  }
}

.cont_05 .in .flxL .img {
  position: relative;
}

.cont_05 .in .flxL .img::before {
  content: url(../images/home/prof_deco.png);
  position: absolute;
  right: -98px;
  bottom: -20px;
}

@media only screen and (min-width: 835px) and (max-width: 1200px) {
  .cont_05 .in .flxL .img::before {
    content: url(../images/home/prof_deco_samall.png);
    right: -20px;
  }
}

@media only screen and (min-width: 835px) and (max-width: 900px) {
  .cont_05 .in .flxL .img::before {
    right: 0;
  }
}

@media screen and (max-width: 834px) {
  .cont_05 .in .flxL .img::before {
    content: url(../images/home/prof_deco_samall.png);
    right: 0;
  }
}

.flexWrap {
  position: relative;
}

@media screen and (max-width: 834px) {
  .flexWrap {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.flexWrap::before {
  content: "";
  background-color: #e4007f;
  width: 100%;
  height: 595px;
  position: absolute;
  bottom: -100px;
  left: 0;
  right: 0;
}

@media screen and (max-width: 834px) {
  .flexWrap::before {
    display: none;
  }
}

.flexWrap3 {
  position: relative;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
}

@media screen and (max-width: 834px) {
  .flexWrap3 {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.flexWrap3::before {
  content: "";
  width: 100%;
  height: 1px;
  background-color: #e4007f;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (min-width: 835px) and (max-width: 900px) {
  .flexWrap3::before {
    display: none;
  }
}

@media screen and (max-width: 834px) {
  .flexWrap3::before {
    display: none;
  }
}

.flex_01 {
  justify-content: space-between;
}

.flex_01 .box {
  width: calc((100% - 160px) / 3);
  text-align: center;
  position: relative;
  overflow: inherit !important;
  opacity: 0;
}

.flex_01 .box:nth-of-type(1)::before {
  content: url(../images/home/features_deco_01.png);
  position: absolute;
  top: -50px;
  left: -50px;
}

.flex_01 .box:nth-of-type(2) {
  margin-top: 50px;
}

.flex_01 .box:nth-of-type(2)::before {
  content: url(../images/home/features_deco_02.png);
  position: absolute;
  top: -50px;
  left: -50px;
}

.flex_01 .box:nth-of-type(3) {
  margin-top: 100px;
}

.flex_01 .box:nth-of-type(3)::before {
  content: url(../images/home/features_deco_03.png);
  position: absolute;
  top: -50px;
  left: -50px;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex_01 .box {
    width: calc((100% - 60px) / 2);
    margin-bottom: 40px;
    box-shadow: 0 0 90px rgba(228, 0, 127, 0.17);
  }
}

@media screen and (max-width: 640px) {
  .flex_01 .box {
    width: 100%;
    box-shadow: 0 0 90px rgba(228, 0, 127, 0.17);
    margin-top: 100px !important;
  }
}

.flex_01 .box article > div {
  background-color: #fff;
  padding: 40px 5px;
  font-size: clamp(24px, 3.2vw, 32px);
  color: #e4007f;
  font-weight: bold;
  line-height: 1.25em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex_01 .box article > div .sub {
  font-size: 16px;
  padding: 15px 20px;
  border-radius: 50px;
  background-color: #fef7fb;
  font-weight: 500;
  margin-bottom: 18px;
  line-height: 1em;
  color: #222222;
  display: inline-block;
}

.flex_01 .box article > div .sub2 {
  font-size: 16px;
  display: block;
  font-weight: 500;
  margin-bottom: 18px;
  line-height: 1em;
  color: #222222;
}

.flex_02 {
  justify-content: space-between;
  max-width: 1730px;
  margin: auto;
}

@media only screen and (min-width: 835px) and (max-width: 1730px) {
  .flex_02 {
    padding-left: 50px;
    padding-right: 50px;
  }
}

.flex_02 .box {
  width: calc((100% - 240px) / 4);
  text-align: center;
  position: relative;
  overflow: inherit !important;
  box-shadow: 0 0 90px rgba(228, 0, 127, 0.17);
  opacity: 0;
}

.flex_02 .box:nth-of-type(1) {
  margin-bottom: 150px;
}

.flex_02 .box:nth-of-type(1) .sub:nth-of-type(1) {
  margin-top: 30px;
}

.flex_02 .box:nth-of-type(1)::before {
  content: url(../images/home/course_deco_01.png);
  position: absolute;
  top: -50px;
  left: -50px;
}

@media screen and (max-width: 640px) {
  .flex_02 .box:nth-of-type(1)::before {
    left: 0;
  }
}

.flex_02 .box:nth-of-type(2) {
  margin-top: 50px;
  margin-bottom: 100px;
}

.flex_02 .box:nth-of-type(2) .time:nth-of-type(1) {
  margin-top: 30px;
}

.flex_02 .box:nth-of-type(2)::before {
  content: url(../images/home/course_deco_02.png);
  position: absolute;
  top: -50px;
  left: -50px;
}

@media screen and (max-width: 640px) {
  .flex_02 .box:nth-of-type(2)::before {
    left: 0;
  }
}

.flex_02 .box:nth-of-type(3) {
  margin-top: 100px;
  margin-bottom: 50px;
}

.flex_02 .box:nth-of-type(3) .sub2:nth-of-type(1) {
  margin-top: 30px;
}

.flex_02 .box:nth-of-type(3)::before {
  content: url(../images/home/course_deco_03.png);
  position: absolute;
  top: -50px;
  left: -50px;
}

@media screen and (max-width: 640px) {
  .flex_02 .box:nth-of-type(3)::before {
    left: 0;
  }
}

.flex_02 .box:nth-of-type(4) {
  margin-top: 150px;
}

.flex_02 .box:nth-of-type(4) .sub2:nth-of-type(1) {
  margin-top: 30px;
}

.flex_02 .box:nth-of-type(4)::before {
  content: url(../images/home/course_deco_04.png);
  position: absolute;
  top: -50px;
  left: -50px;
}

@media screen and (max-width: 640px) {
  .flex_02 .box:nth-of-type(4)::before {
    left: 0;
  }
}

@media only screen and (min-width: 835px) and (max-width: 1200px) {
  .flex_02 .box {
    width: calc((100% - 60px) / 2);
    margin-bottom: 40px;
    box-shadow: 0 0 90px rgba(228, 0, 127, 0.17);
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex_02 .box {
    width: calc((100% - 60px) / 2);
    margin-bottom: 40px;
    box-shadow: 0 0 90px rgba(228, 0, 127, 0.17);
  }
}

@media screen and (max-width: 640px) {
  .flex_02 .box {
    width: 100%;
    margin-bottom: 100px !important;
    margin-top: 0 !important;
  }
}

.flex_02 .box article h3 {
  background-color: #e4007f;
  font-size: clamp(20px, 2vw, 20px);
  font-weight: bold;
  padding: 20px 15px;
  color: #fff;
}

.flex_02 .box article > div {
  padding: 40px 20px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.5em;
}

.flex_02 .box article > div .sub {
  font-size: 16px;
  padding: 15px 20px;
  border-radius: 50px;
  background-color: #fef7fb;
  font-weight: 500;
  margin-bottom: 18px;
  line-height: 1.25em;
  color: #222222;
  display: inline-block;
}

.flex_02 .box article > div .price {
  font-family: 'Montserrat', sans-serif;
}

.flex_02 .box article > div .sub2 {
  font-size: 16px;
  display: block;
  font-weight: bold;
  margin-bottom: 18px;
  line-height: 1.25em;
}

.flex_02 .box article > div .tal {
  text-align: left;
  font-size: 14px;
  margin-top: 50px;
  width: 100%;
}

.flex_02 .box article > div .btn {
  color: #222222;
  position: relative;
  display: block;
  margin-top: 20px;
}

.flex_02 .box article > div .btn::before {
  content: url(../images/home/arrow_icon.png);
  position: absolute;
  left: -18px;
  top: 0;
}

.flex_03 {
  justify-content: space-between;
  max-width: 1730px;
  margin: auto;
}

@media only screen and (min-width: 835px) and (max-width: 1730px) {
  .flex_03 {
    padding-left: 50px;
    padding-right: 50px;
  }
}

.flex_03 .box {
  width: calc((100% - 180px) / 4);
  text-align: center;
  position: relative;
  overflow: inherit !important;
  box-shadow: 0 0 90px rgba(228, 0, 127, 0.17);
  padding: 50px 30px;
  background-color: #fff;
}

.flex_03 .box:nth-of-type(1)::before {
  content: url(../images/home/features_deco_01.png);
  position: absolute;
  top: -50px;
  left: -50px;
}

@media screen and (max-width: 834px) {
  .flex_03 .box:nth-of-type(1)::before {
    left: -20px;
  }
}

.flex_03 .box:nth-of-type(2)::before {
  content: url(../images/home/features_deco_02.png);
  position: absolute;
  top: -50px;
  left: -50px;
}

@media screen and (max-width: 834px) {
  .flex_03 .box:nth-of-type(2)::before {
    left: -20px;
  }
}

.flex_03 .box:nth-of-type(3)::before {
  content: url(../images/home/features_deco_03.png);
  position: absolute;
  top: -50px;
  left: -50px;
}

@media screen and (max-width: 834px) {
  .flex_03 .box:nth-of-type(3)::before {
    left: -20px;
  }
}

.flex_03 .box:nth-of-type(4)::before {
  content: url(../images/home/features_deco_04.png);
  position: absolute;
  top: -50px;
  left: -50px;
}

@media screen and (max-width: 834px) {
  .flex_03 .box:nth-of-type(4)::before {
    left: -20px;
  }
}

@media only screen and (min-width: 835px) and (max-width: 900px) {
  .flex_03 .box {
    width: calc((100% - 60px) / 2);
    margin-bottom: 100px !important;
    margin-top: 0 !important;
    box-shadow: 0 0 90px rgba(228, 0, 127, 0.17);
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex_03 .box {
    width: calc((100% - 60px) / 2);
    margin-bottom: 100px !important;
    margin-top: 0 !important;
    box-shadow: 0 0 90px rgba(228, 0, 127, 0.17);
  }
}

@media screen and (max-width: 640px) {
  .flex_03 .box {
    width: 100%;
    margin-bottom: 100px !important;
    margin-top: 0 !important;
  }
}

.flex_03 .box article h3 {
  font-size: 1.5em;
  margin: 40px 0;
}

.flex_03 .box article > div {
  line-height: 2.6em;
  color: #595959;
  text-align: left;
}

.flex_04 {
  justify-content: space-between;
}

.flex_04 h3 {
  font-size: 1.5em;
  line-height: 1.25em;
  color: #e4007f;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  letter-spacing: 0.27em;
  margin-bottom: 30px;
}

.flex_04 .box {
  width: 48%;
}

.flex_04 .box article > div {
  line-height: 3em;
  color: #595959;
}

@media screen and (max-width: 640px) {
  .flex_04 .box {
    width: 100%;
    margin-bottom: 40px;
  }
  .flex_04 .box:last-of-type {
    margin-bottom: 0;
  }
}

.dl_01 {
  color: #595959;
}

.dl_01 dt {
  border: none !important;
  padding: 15px 0 !important;
}

@media screen and (max-width: 640px) {
  .dl_01 dt {
    display: block !important;
    width: 100% !important;
    text-align: left;
  }
}

.dl_01 dd {
  border: none !important;
  padding: 15px 0 !important;
  text-align: right;
  font-family: 'Montserrat', sans-serif;
}

@media screen and (max-width: 640px) {
  .dl_01 dd {
    display: block !important;
    width: 100% !important;
  }
}

@media screen and (max-width: 640px) {
  .dl_01 dl {
    display: block !important;
  }
}

.dl_02 {
  color: #595959;
}

.dl_02 dt {
  border: none !important;
  padding: 40px 0 !important;
  color: #f2a5c7;
}

@media screen and (max-width: 640px) {
  .dl_02 dt {
    display: block !important;
    width: 100% !important;
    padding: 40px 0 20px !important;
  }
}

.dl_02 dd {
  border: none !important;
  padding: 40px 0 !important;
}

@media screen and (max-width: 640px) {
  .dl_02 dd {
    display: block !important;
    width: 100% !important;
    padding: 20px 0 40px !important;
  }
}

.dl_02 dl {
  border-bottom: 1px solid #f7f7f7;
}

@media screen and (max-width: 640px) {
  .dl_02 dl {
    display: block !important;
  }
}

.text div {
  line-height: 3em;
  color: #595959;
}

.text h3 {
  font-size: 1.5em;
  margin-bottom: 60px;
  line-height: 1.5em;
}

.text2 div {
  line-height: 2.25em;
  color: #595959;
}

.bnr {
  padding-left: 0;
  padding-right: 0;
}

.gmap iframe {
  width: 100%;
  vertical-align: bottom;
}

.top-sns .sns_text {
  display: none;
}

.top-sns .slick-slide {
  margin-right: 50px;
}

.btn a {
  color: #fff;
  position: relative;
  display: block;
  padding: 27px;
  width: 300px;
  font-size: 1.12em;
  background-color: #e4007f;
  border-radius: 50px;
  font-family: 'Montserrat', sans-serif;
}

.btn a:before {
  font-family: "Font Awesome 5 pro";
  content: "\f061";
  position: absolute;
  right: 5%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: none;
  border: none;
  border-radius: 10px;
  box-shadow: inset 0 0 2px #ccc;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
  box-shadow: none;
}

.title_01, .title_02, .title_03, .sub_title_01, .sub_title_02 {
  overflow: hidden;
  display: block;
}

/* アニメーションで傾斜がついている文字列を水平に戻す*/
.smoothTextTrigger {
  transition: .8s ease-in-out;
  transform: translate3d(0, 100%, 0) skewY(12deg);
  transform-origin: left;
  display: block;
}

.smoothTextTrigger.smoothTextAppear {
  transform: translate3d(0, 0, 0) skewY(0);
}

/* 上下のアニメーション*/
.text, .text2 {
  overflow: hidden;
}

.text .slide-in, .text2 .slide-in {
  display: block;
  transition: transform 2s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: translate3d(0, 100%, 0);
}

.text .slide-in.scroll-in, .text2 .slide-in.scroll-in {
  transform: translate3d(0, 0, 0);
}

/* アニメーションスタートの遅延時間を決めるCSS*/
.delay-time02 {
  animation-delay: 0.2s;
}

.delay-time04 {
  animation-delay: 0.6s;
}

.delay-time06 {
  animation-delay: 1s;
}

/*==================================================
スタート時は要素自体を透過0にするためのopacity:0;を指定する
===================================*/
.box {
  opacity: 0;
}

/*==================================================
動き自体の指定：今回は「ふわっ」
===================================*/
.fadeUp {
  animation-name: fadeUpAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes fadeUpAnime {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

div.items > article a {
  color: #111;
  text-decoration: none !important;
  font-size: 16px;
}

div.under ul li a {
  font-weight: 400;
  font-family: "Noto Serif JP", "Times New Roman", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  opacity: 1;
  border: 1px solid #333;
  color: #333 !important;
  padding: 10px;
}

div.under ul li a:hover {
  border: 1px solid #b0ddd9;
  background: #b0ddd9;
  color: #fff !important;
}

div.select > select {
  height: 100%;
}

div.search form input {
  padding: 10px 37px 10px 5px;
}

div.order ul li a {
  color: #111;
}

div.items > article p.price {
  color: red;
}

div.crumb {
  display: none;
}

div.item_view div.item_option {
  width: 100% !important;
}

div.item_option dl, button.addcart {
  width: 50% !important;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  div.item_option dl, button.addcart {
    margin: 0 auto;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .addcart {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

div.item_view div.struct {
  display: block;
}

div.photo div.large {
  width: auto;
}

div.photo {
  margin-left: 0;
}

h2.title_h2 {
  background: none !important;
  border-top: none !important;
  border-bottom: 1px solid #CCCCCC;
  font-size: 20px !important;
  line-height: 1.3;
  margin: 65px 0 20px !important;
  padding: 10px !important;
}

h2.title_h2 span {
  border: none !important;
  padding: 0 !important;
}

ul.item_list li {
  margin-bottom: 10px !important;
}

ul.item_list li h3.title_h3 {
  font-size: 18px !important;
}

.btn_cart a {
  background: url(../images/common/ico_cart_w.png) no-repeat 10px 12px #000000 !important;
  background-size: 30px auto !important;
  color: #FFFFFF !important;
  display: block !important;
  padding: 10px 10px 10px 50px !important;
  width: 100% !important;
  border: 1px solid #ffffff;
}

.btn_cart a:hover {
  background: url(../images/common/ico_cart_w.png) no-repeat 10px 12px #CCCCCC !important;
  background-size: 30px auto !important;
}

.sort_catalog {
  overflow: hidden;
}

.sort_catalog ul {
  display: flex;
}

.item_title, .wishlist {
  margin: 0 !important;
}

.shop_cont h2.title_h2, .item_info h2.title_h2 {
  border-top: 2px solid #CCCCCC;
}

.shop_cont a, .item_info a {
  color: #CCCCCC;
}

.shop_cont a:hover, .item_info a:hover {
  text-decoration: none;
}

.item_title h2 {
  border-top: 2px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
}

.shop_cont table {
  table-layout: auto;
}

.flex1 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex1._top {
  align-items: flex-start;
}

.flex1._center {
  align-items: center;
}

.flex1:before {
  width: calc(100% / 1 - 1%);
  content: "";
  order: 1;
}

.flex1:before {
  width: calc(100% / 1 - 1%);
  content: "";
  order: 1;
}

.flex1:after {
  width: calc(100% / 1 - 1%);
  content: "";
  order: 2;
}

.flex1 .box {
  width: calc(100% / 1 - 1%);
  margin-bottom: 2%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  ._tabwidth1 .box {
    width: 100%;
  }
  ._tabwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._tabwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

@media screen and (max-width: 640px) {
  ._spwidth1 .box {
    width: 100%;
  }
  ._spwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._spwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

.flex1 .box img {
  width: 100% !important;
}

.flex2 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex2._top {
  align-items: flex-start;
}

.flex2._center {
  align-items: center;
}

.flex2:before {
  width: calc(100% / 2 - 1%);
  content: "";
  order: 1;
}

.flex2:before {
  width: calc(100% / 2 - 1%);
  content: "";
  order: 1;
}

.flex2:after {
  width: calc(100% / 2 - 1%);
  content: "";
  order: 2;
}

.flex2 .box {
  width: calc(100% / 2 - 1%);
  margin-bottom: 2%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  ._tabwidth1 .box {
    width: 100%;
  }
  ._tabwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._tabwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

@media screen and (max-width: 640px) {
  ._spwidth1 .box {
    width: 100%;
  }
  ._spwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._spwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

.flex2 .box img {
  width: 100% !important;
}

.flex3 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex3._top {
  align-items: flex-start;
}

.flex3._center {
  align-items: center;
}

.flex3:before {
  width: calc(100% / 3 - 1%);
  content: "";
  order: 1;
}

.flex3:before {
  width: calc(100% / 3 - 1%);
  content: "";
  order: 1;
}

.flex3:after {
  width: calc(100% / 3 - 1%);
  content: "";
  order: 2;
}

.flex3 .box {
  width: calc(100% / 3 - 1%);
  margin-bottom: 2%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  ._tabwidth1 .box {
    width: 100%;
  }
  ._tabwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._tabwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

@media screen and (max-width: 640px) {
  ._spwidth1 .box {
    width: 100%;
  }
  ._spwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._spwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

.flex3 .box img {
  width: 100% !important;
}

.flex4 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex4._top {
  align-items: flex-start;
}

.flex4._center {
  align-items: center;
}

.flex4:before {
  width: calc(100% / 4 - 1%);
  content: "";
  order: 1;
}

.flex4:before {
  width: calc(100% / 4 - 1%);
  content: "";
  order: 1;
}

.flex4:after {
  width: calc(100% / 4 - 1%);
  content: "";
  order: 2;
}

.flex4 .box {
  width: calc(100% / 4 - 1%);
  margin-bottom: 2%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  ._tabwidth1 .box {
    width: 100%;
  }
  ._tabwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._tabwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

@media screen and (max-width: 640px) {
  ._spwidth1 .box {
    width: 100%;
  }
  ._spwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._spwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

.flex4 .box img {
  width: 100% !important;
}

.square._100 .square-inner:before {
  padding-top: 100%;
}

.square._75 .square-inner:before {
  padding-top: 75%;
}

.square._66 .square-inner:before {
  padding-top: 66%;
}

.square td .square-inner {
  display: block;
  position: relative;
}

.square td .square-inner:before {
  position: relative;
  z-index: 1;
  content: "";
  display: block;
  width: 100% !important;
}

.square td .square-inner img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  font-family: "object-fit:cover;";
  width: 100% !important;
  height: 100% !important;
}

.square > .square-inner {
  display: block;
  position: relative;
}

.square > .square-inner .itext {
  float: none !important;
  margin-right: 0 !important;
}

.square > .square-inner .itext img {
  float: none !important;
  margin-right: 0 !important;
}

.square > .square-inner:before {
  position: relative;
  z-index: 1;
  content: "";
  display: block;
  width: 100% !important;
}

.square > .square-inner img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  font-family: "object-fit:cover;";
  width: 100% !important;
  height: 100% !important;
}

.square > .box .itext {
  float: none !important;
  margin-right: 0 !important;
}

.square > .box .itext img {
  float: none !important;
  margin-right: 0 !important;
}

.square > .box .square-inner {
  display: block;
  position: relative;
}

.square > .box .square-inner:before {
  position: relative;
  z-index: 1;
  content: "";
  display: block;
  width: 100% !important;
}

.square > .box .square-inner img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  font-family: "object-fit:cover;";
  width: 100% !important;
  height: 100% !important;
}

.bg100 {
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
}

.flex2cl_01 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_01 {
    flex-direction: column-reverse;
  }
}

.flex2cl_01 .flxL {
  width: 57%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_01 .flxL {
    width: 100%;
  }
}

.flex2cl_01 .flxR {
  width: 40%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_01 .flxR {
    width: 100%;
    margin-bottom: 20px;
  }
}

.flex2cl_01 .flxR .img {
  width: 100%;
}

.flex2cl_01 .flxR .img img {
  width: 100% !important;
}

.flex2cl_02 {
  border: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -4%;
  margin-bottom: 50px;
}

@media screen and (max-width: 1200px) {
  .flex2cl_02 {
    margin-left: -2.4390%;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_02 {
    justify-content: space-between;
    margin-left: 0;
  }
}

.flex2cl_02 .box {
  position: relative;
  width: 46%;
  margin-left: 4%;
  margin-bottom: 20px;
}

@media screen and (max-width: 1200px) {
  .flex2cl_02 .box {
    width: 47.5609%;
    margin-left: 2.4390%;
    display: block;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_02 .box {
    width: 100%;
    margin-left: 0;
  }
}

.flex2cl_02 .box img {
  width: 100% !important;
}

.flex2cl_02 .box tr:nth-child(2) {
  font-size: 18px;
  font-weight: 700;
}

.flex2cl_02 .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

.flex3cl {
  border: 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -2.43902%;
}

@media screen and (max-width: 1200px) {
  .flex3cl {
    margin-left: -2.4390%;
  }
}

@media screen and (max-width: 640px) {
  .flex3cl {
    justify-content: space-between;
    margin-left: 0;
  }
}

.flex3cl .box {
  position: relative;
  width: 30.89431%;
  margin-left: 2.43902%;
  margin-bottom: 30px;
}

@media screen and (max-width: 1200px) {
  .flex3cl .box {
    width: 47.5609%;
    margin-left: 2.4390%;
    display: block;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 640px) {
  .flex3cl .box {
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }
}

.flex3cl .box img {
  width: 100% !important;
}

.flex3cl .box tr:nth-child(2) {
  font-size: 18px;
  font-weight: 700;
}

.flex3cl .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

.flex4cl {
  border: 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -3.22581%;
}

@media screen and (max-width: 1200px) {
  .flex4cl {
    margin-left: -2.4390%;
  }
}

@media screen and (max-width: 640px) {
  .flex4cl {
    justify-content: space-between;
    margin-left: 0;
  }
}

.flex4cl .box {
  position: relative;
  width: 21.77419%;
  margin-left: 3.22581%;
  margin-bottom: 30px;
}

@media screen and (max-width: 1200px) {
  .flex4cl .box {
    width: 47.5609%;
    margin-left: 2.4390%;
    display: block;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 640px) {
  .flex4cl .box {
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }
}

.flex4cl .box img {
  width: 100% !important;
}

.flex4cl .box tr:nth-child(2) {
  font-size: 18px;
  font-weight: 700;
}

.flex4cl .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .img .imgL, .img .imgR, .img .imgL, .deco .imgL, .deco .imgR, .deco .imgL {
    max-width: 100% !important;
  }
}

/* スクロールをしたら出現する要素にはじめに透過0を指定　*/
.fadeRTrigger, .fadeLTrigger, .fadeRTrigger2, .fadeLTrigger2 {
  opacity: 0;
  transition: opacity 2s;
}

.fadeIn {
  animation-name: fadeInAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes fadeInAnime {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* 右から */
.fadeR {
  animation-name: fadeRightAnime;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes fadeRightAnime {
  from {
    opacity: 0;
    transform: translateX(100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeR2 {
  animation-name: fadeRightAnime2;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes fadeRightAnime2 {
  from {
    opacity: 0;
    transform: translateX(100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* 左から */
.fadeL {
  animation-name: fadeLeftAnime;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes fadeLeftAnime {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeL2 {
  animation-name: fadeLeftAnime2;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes fadeLeftAnime2 {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.form dt {
  border: none !important;
}

.form dd {
  border: none !important;
}

.form dl {
  border-bottom: 1px solid #ccc !important;
  padding: 15px 0;
}

.policy_cont h2 {
  font-size: 1.4em;
  border-bottom: 1px solid #e4007f;
  padding-bottom: 15px;
  font-weight: bold;
  margin: 50px 0 20px 0;
}

.mail {
  display: none;
}

.confirm {
  margin-top: 50px;
}

.policy {
  padding: 10px 0;
  text-align: center;
}

.message {
  margin-top: 200px !important;
}
